:root {
    background-color: #F0F3F5;
    width: 100%;
    min-height: 100vh;
}

.container-login {

    width: 400px;
    height: 508px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 10px 0.5px rgb(0 0 0 / 15%);


}

.wrap-login {
    padding: 30px 30px 30px 30px;
    width: 100%;
    height: 100%;
}

.login-form-title {
    display: block;
    font-size: 28px;
    color: #141d70;
    line-height: 1.2;
    text-align: left;
    padding: 10px 0px 05px 0px;
    position: relative;

}

.login-form-title img {
    top: 230px;
    left: 790px;
    width: 231px;
    height: 89px;

}

.wrap-input {
    width: 330px;
    height: 44px;
    position: relative;
    margin: 20px 5px 22px 0px;
}

.wrap-input input {

    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 2px solid #cdcecf;
    background-color: transparent;


}

.login-form-btn {

    position: relative;
    width: 339px;
    height: 46px;
    border-radius: 5px;
    border: 2px solid #cdcecf;
    background-color: #7968cc;
    margin: 20px 0px 0px 0px;
   



}
.entrar{
    font-size: 14px;
    color: #f8f8f8;
}



.login-form-btn:hover {
    background-color: #5059ab;
    cursor: pointer;
}

.wrap-input label {
    position: absolute;
    bottom: 6px;
    left: 0;
    color: #616161;
    transition: 0.2s;
    left: 10px;
    background: white;
    padding: 0px 3px;
    font-size: 14px;
    border-radius: 10px;

}

.wrap-input .txt-danger {
    position: relative;
    color: #c80000;
}

.form_input:focus~.form_label,
.form_input:not(:placeholder-shown).form_input:not(:focus)~.form_label {
    transform: translateY(-23px);
    font-size: 12px;
    color: #959595;


}

.container-recupera-senha {
    left: 50px;
    color: #81dcea;
    font-size: 15px;
    
}