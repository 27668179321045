@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap);

body {
  font-family: "Titillium Web", sans-serif !important;
  font-size: 14px;
  letter-spacing: 0.1px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f3f3f3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App>.custom-control {
  padding-left: 0;
}

$headerAppHeight: 36px;

.header-app {
  height: $headerAppHeight;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: stretch;
  // perspective: 1800px;
  width: 100%;
  height: 100vh;
}

#content {
  width: calc(100% - 200px);
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transform-origin: center right;
}

#sidebarCollapse {
  width: 40px;
  height: 40px;
  background: #fff;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  left: 10px;
}

#sidebarCollapse span {
  width: 80%;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #555;
  transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
}

#sidebarCollapse span:first-of-type {
  /* rotate first one */
  transform: rotate(45deg) translate(2px, 2px);
}

#sidebarCollapse span:nth-of-type(2) {
  /* second one is not visible */
  opacity: 0;
}

#sidebarCollapse span:last-of-type {
  /* rotate third one */
  transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
  /* no rotation */
  transform: none;
  /* all bars are visible */
  opacity: 1;
  margin: 5px auto;
}

#sidebar {
  min-width: 200px;
  max-width: 200px;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(28, 60, 87, 0.18), transparent));
  background-image: linear-gradient(270deg, rgba(28, 60, 87, 0.18), transparent);
  background-color: #6e4e9e;
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transform-origin: center left;
  /* Set the transformed position of sidebar to center left side. */
  z-index: 60;
}

#sidebar.active+#content {
  width: 100%;
}

// #sidebar.active + #content .custom-horizontal-scroll {
//   width: 100%;
//   max-width: 100%;
// }

#sidebar.active {
  margin-left: -200px;
  transform: rotateY(100deg);
  /* Rotate sidebar vertically by 100 degrees. */
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.nav-menu {
  padding: 0;
  list-style: none;
  margin: 0;
}

.nav-menu li a,
.nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  outline: 5px;
  padding: 11px 11px;
  font-size: 0.875rem !important;
  color: #bdafd1;
  font-weight: 400 !important;
  text-decoration: none;
  position: relative;
  height: 35px;
}

.nav-link {
  letter-spacing: 2px;
  font-size: 16px !important;
}

.nav-menu li a:hover {
  color: #fff;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}

.nav-menu li a:hover>[class*="fa-"] {
  color: #a8a6ac;
}

.nav-menu li a:focus {
  color: #fff;
}

.nav-menu li>ul {
  background-color: rgba(0, 0, 0, 0.1);
}

.nav-menu li>ul li a {
  color: #af9fc7;
  padding: 11px 28px 11px 28px;
}

.nav-menu li>ul li a:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}

.nav-menu li>ul li.active>a {
  color: #fff;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
}

.nav-menu li>ul li>ul li a {
  color: #aa99c4;
  padding: 0.8125rem 2rem 0.8125rem 4.75rem;
}

.nav-menu li>ul li>ul li a:hover {
  color: #fff;
}

.nav-menu li:last-child {
  margin-bottom: 0;
}

.nav-menu:last-child {
  margin-bottom: 0;
}

.nav-item.nav-error {
  a {
    color: #c80000 !important;

    &:after {
      content: "\f06a";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      margin-left: 4px;
      color: #c80000 !important;
    }
  }
}

.table-wrapper {
  overflow-x: scroll;
  margin-bottom: 10px;
}

.custom-horizontal-scroll {
  overflow-x: scroll;
  scrollbar-track-color: #838383;
  background-color: rgba(0, 0, 0, 0);
  -webkit-background-clip: text;
  background-clip: text;
  /* uncomment following line to see the trick in actions */
  /*-webkit-text-fill-color: transparent; */
  transition: background-color 0.8s;
  min-width: 100%;

  &:hover {
    background-color: rgba(131, 131, 131, 0.9);
  }

  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: inherit;
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}

.table.dataTable {
  clear: both;
  border: 1px solid #e9e9e9;
  border-collapse: separate !important;
  border-spacing: 0;
  margin-top: 0 !important;
  width: 100%;
  max-width: none !important;

  td {
    position: relative;
    padding: 0.3rem 0.75rem;
    overflow: hidden;
    white-space: nowrap;
  }
}

.dataTable-filter {
  margin-bottom: 10px;
}

.table-bordered {
  border: 1px solid #e9e9e9;
}

table.table-bordered.dataTable {
  border-right-width: 0;
}

table.dataTable thead th {
  border-top: none;
  border-bottom: none;
  border-left: none;
}

table.dataTable thead th:last-child {
  border-right-width: 0;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

td {
  display: table-cell;
  vertical-align: inherit;
}

.table td,
.table th {
  padding: 0.4rem 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9e9e9;
  box-sizing: content-box;
  border-left-width: 0;
  border-bottom-width: 0;
}

.subheader:not(:empty) {
  margin-bottom: 1.5rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.subheader {
  margin-bottom: 1.5rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.subheader-icon {
  color: #a8a6ac;
  margin-right: 0.38rem;
}

.subheader-title {
  font-size: 1.375rem;
  font-weight: 500;
  color: #505050;
  text-shadow: #fff 0 1px;
  margin: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.page-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 36px);
  overflow-y: auto;
}

.fw-300 {
  font-weight: 300 !important;
}

.fs-i {
  font-style: italic;
}

.panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid #e0e0e0;
  border-radius: 4px;
  -webkit-transition: border 0.5s ease-out;
  transition: border 0.5s ease-out;
}

.panel .panel-container {
  border-radius: 0 0 4px 4px;
}

.panel .panel-container .panel-content {
  padding: 1rem 1rem;
}

.panel .panel-container .panel-content:last-child,
.panel .panel-container .panel-content:only-child {
  border-radius: 0 0 4px 4px;
}

$primary: #11998e;
$secondary: #38ef7d;
$white: #333;
$gray: #9b9b9b;

.field-group {
  position: relative;
  padding: 14px 0 0;
  width: 100%;
}

.checkbox-group {
  position: relative;
}

.field-checkbox {
  position: absolute;
  opacity: 0;

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  &+label::before {
    content: "\f0c8";
    font-family: "Font Awesome 5 Pro";
    color: #11998e;
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    font-size: 16px;
    font-weight: 900;
  }

  &:checked+label::before {
    content: "\f14a";
  }
}

input[type='radio']:after {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  top: -6px;
  left: -10px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  top: -6px;
  left: -10px;
  position: relative;
  background-color: #6e4e9e;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.field-input.disabled {
  opacity: 0.8;
}

.field-input {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid $gray;
  outline: 0;
  font-size: 18px;
  color: $white;
  padding: 6px 0 4px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown~.field-label {
    font-size: 18px;
    cursor: text;
    top: 20px;
  }
}

.hidden {
  display: none;
}

.field-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 16px;
  letter-spacing: 1px;
  color: $gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.field-input:focus {
  ~.field-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 16px;
    color: $primary;
  }

  border-width: 1px;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}

/* reset input */
.field-input {

  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.dataTable-header {
  position: relative;
}

.dataTable-header .custom-select {
  width: 120px;
  float: right;
  position: absolute;
  right: 0;
  bottom: 0;
}

.dataTables_info {
  font-size: 13px;
  margin-top: 10px;
}

.dataTables_paginate .pagination {
  float: right;
}

.edit-buttons+span {
  transition: all 0.3s;
}

.edit-buttons {
  background-color: #6ccce4;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
  z-index: 2;

  &:hover {
    width: 92px;
  }

  &:hover+span {
    padding-left: 90px;
  }

  ul {
    height: 100%;
    padding-left: 8px;
    width: 92px;

    li {
      display: inline;

      a {
        width: 28px;
        height: 100%;
        outline: none !important;
        padding: 0.25rem 0.1rem;
        border-radius: 0;
        line-height: 22.1px;
      }
    }
  }
}

.form-group {
  margin-bottom: 0.2rem;
}

.has-error {
  color: #c80000 !important;

  .field-input {
    border-color: #c80000 !important;
  }

  .field-label {
    color: #c80000 !important;
  }

  .field-input:focus {
    ~.field-label {
      color: #c80000 !important;
    }
  }
}

.select-required+label {
  color: #c80000 !important;
}

small {
  letter-spacing: 1px;
}

.select-required+label+small {
  color: #c80000 !important;
  letter-spacing: 1px;
}

.css-tlfecz-indicatorContainer {
  padding-top: 5px;
}

.container {
  position: relative;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-footer {
  z-index: 50;
  height: 70px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  -webkit-box-shadow: 0px -5px 14px 5px rgba(106, 115, 78, 0.2);
  box-shadow: 0px 1px 15px 4px rgba(106, 115, 78, 0.2);
  padding: 1rem 4rem;

  .btn {
    margin-left: 15px;
  }

  .btn>i {
    padding-right: 5px;
  }
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.dl-horizontal {
  display: grid;
  grid-template-columns: 15% auto;
  margin-top: 1rem;
  align-items: center;
  grid-auto-rows: 25px;

  dt {
    grid-column: 1;
    text-align: right;
    padding-right: 15px;
  }

  dd {
    grid-column: 2;
    margin-bottom: 0;
  }
}

.btn-header-new {
  width: 90px;
  position: absolute;
  right: 25px;
}

.nav-tabs-clean {
  height: 45px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.nav-item {
  min-width: 110px;
  width: 110px;
}

.nav-tabs-clean .nav-item .nav-link {
  border-radius: 0;
  border: 0;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  border-bottom: 1px solid transparent;
  padding-top: 12px;
}

.nav-tabs-clean .nav-item .nav-link.active {
  border-bottom: 1px solid #886ab5;
  color: #886ab5;
}

.nav-tabs-clean .nav-item .nav-link:hover {
  color: #886ab5;
}

.nav-tabs .nav-item .nav-link:not(:hover) {
  color: inherit;
}

.nav-tabs .nav-item .nav-link.active:not(:hover) {
  color: #333;
}

.nav-tabs .nav-item .nav-link:hover:not(.active) {
  color: inherit;
}

.nav .nav-link:not([class^="btn-"]):not([class*=" btn-"]) {
  font-weight: 500;
  font-size: 0.8125rem;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #886ab5;
}

.select-label {
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: -5px;
}

.smart-select {
  outline: none !important;
  margin-top: 20px;

  div[class*="control"] {
    border: 1px solid #b3b3b3;
  }
}

.smart-select+label {
  position: absolute;
  top: 0;
}

.telefone-item {
  margin-top: 15px;
  position: relative;

  .remove-button {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &:hover {
    .remove-button {
      display: block;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.contato-item {
  margin-top: 15px;
}

.info-card {
  position: relative;
  width: 16.875rem;
  height: 9.53rem;
  color: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 2rem;
  -webkit-transition: all 0.7s cubic-bezier(0.34, 1.25, 0.3, 1);
  transition: all 0.7s cubic-bezier(0.34, 1.25, 0.3, 1);
}

.info-card img.cover {
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  height: auto;
}

.info-card .profile-image {
  width: 3.125rem;
  height: auto;
  display: inline-block;
  z-index: 2;
  position: relative;
}

.info-card .info-card-text {
  margin-left: 1rem;
  color: inherit;
  text-shadow: #000 0 1px;
  z-index: 1;
  position: relative;
  line-height: normal;
}

.info-card .info-card-text>span {
  font-weight: 300;
}

.info-card-text {
  font-size: 0.875rem;
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  line-height: 1.35;
}

.info-card-text>span {
  font-size: 0.875rem;
  display: block;
  font-weight: 300;
}

.shadow-0 {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.shadow-1,
.shadow-hover-1:hover {
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.1);
}

.shadow-2,
.shadow-hover-2:hover {
  -webkit-box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.1);
}

.shadow-3,
.shadow-hover-3:hover {
  -webkit-box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.125);
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.125);
}

.shadow-4,
.shadow-hover-4:hover {
  -webkit-box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.125);
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.125);
}

.shadow-5,
.shadow-hover-5:hover {
  -webkit-box-shadow: 0 0.125rem 0.325rem rgba(0, 0, 0, 0.175);
  box-shadow: 0 0.125rem 0.325rem rgba(0, 0, 0, 0.175);
}

.shadow-hover-inset-1:hover,
.shadow-inset-1 {
  -webkit-box-shadow: inset 0 0.25rem 0.125rem 0 rgba(33, 37, 41, 0.025);
  box-shadow: inset 0 0.25rem 0.125rem 0 rgba(33, 37, 41, 0.025);
}

.shadow-hover-inset-2:hover,
.shadow-inset-2 {
  -webkit-box-shadow: inset 0 0.2rem 0.325rem rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 0.2rem 0.325rem rgba(0, 0, 0, 0.04);
}

.shadow-hover-inset-3:hover,
.shadow-inset-3 {
  -webkit-box-shadow: inset 0 0.2rem 0.325rem rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 0.2rem 0.325rem rgba(0, 0, 0, 0.05);
}

.shadow-hover-inset-4:hover,
.shadow-inset-4 {
  -webkit-box-shadow: inset 0 0.25rem 0.5rem rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 0.25rem 0.5rem rgba(0, 0, 0, 0.06);
}

.shadow-hover-inset-5:hover,
.shadow-inset-5 {
  -webkit-box-shadow: inset 0 0.35rem 0.5rem rgba(0, 0, 0, 0.07);
  box-shadow: inset 0 0.35rem 0.5rem rgba(0, 0, 0, 0.07);
}

.shadow-sm-hover {
  &:hover {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
}

.shadow-hover:hover {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg-hover:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.border-faded {
  border: 1px solid rgba(29, 29, 29, 0.1);
}

.mb-g {
  margin-bottom: 1.5rem !important;
}

@media (max-width: 768px) {

  /* Reversing the behavior of the sidebar: 
         it'll be rotated vertically and off canvas by default, 
         collapsing in on toggle button click with removal of 
         the vertical rotation.   */
  #sidebar {
    margin-left: -200px;
    transform: rotateY(100deg);
  }

  #sidebar.active {
    margin-left: 0;
    transform: none;
  }

  /* Reversing the behavior of the bars: 
         Removing the rotation from the first,
         last bars and reappear the second bar on default state, 
         and giving them a vertical margin */
  #sidebarCollapse span:first-of-type,
  #sidebarCollapse span:nth-of-type(2),
  #sidebarCollapse span:last-of-type {
    transform: none;
    opacity: 1;
    margin: 5px auto;
  }

  /* Removing the vertical margin and make the first and last bars rotate again when the sidebar is open, hiding the second bar */
  #sidebarCollapse.active span {
    margin: 0 auto;
  }

  #sidebarCollapse.active span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
  }

  #sidebarCollapse.active span:nth-of-type(2) {
    opacity: 0;
  }

  #sidebarCollapse.active span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
  }
}

@media (min-width: 992px) {
  .page-content {
    padding: 1.5rem 1.5rem 80px 1.5rem;
  }

  .page-content .panel {
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 992px) {
  .page-content {
    padding: 1.5rem 1.5rem 80px 1.5rem;
    color: #222;
    font-size: 14px;
    min-height: calc(100vh - 6.9375rem);
  }
}

@media (min-width: 1399px) {
  .info-card:hover {
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    will-change: opacity;
  }

  .info-card:hover img.cover {
    opacity: 0.7;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.pa {
  position: absolute;
}

.index-1 {
  z-index: 1;
}